import { defineStore } from 'pinia'
import type { Ref } from 'vue'
import { cloneDeep, unescape } from 'lodash-es'
interface StringDictionary {
  [key: string]: string
}

const INIT_SEARCH_CONDITION = {
  searchKeywordType: 'THREAD_TITLE_AND_CONTENT',
  keywords: '',
  headlineId: '0' as string | undefined
}

const INIT_THREAD_INFO: ThreadInfo = {
  threads: [],
  totalElements: 0,
  totalPages: 0,
  numberOfElements: 0,
  blockStartKey: undefined,
  blockStartNo: undefined
}

export const useBoardStore = defineStore('board', () => {
  const { $api } = useNuxtApp()
  const router = useRouter()
  /* state */
  const headlineList: Ref<Headline[]> = ref([])
  const curPage = ref(0)
  const searchCondition = ref(cloneDeep(INIT_SEARCH_CONDITION))
  const boardInfo = ref({
    boardId: '',
    perPage: 10
  })
  const threadInfo: Ref<ThreadInfo> = ref(cloneDeep(INIT_THREAD_INFO))
  const a2sObjPrefix = ref('')
  function initBoardState() {
    headlineList.value = []
    curPage.value = 0
    searchCondition.value = cloneDeep(INIT_SEARCH_CONDITION)
    threadInfo.value = cloneDeep(INIT_THREAD_INFO)
  }
  function setA2sObjPrefix(prefix: 'Notice' | 'News' | 'FAQ') {
    a2sObjPrefix.value = prefix
  }
  /* getters */
  const headlineMap = computed(() => {
    const map: StringDictionary = {}
    headlineList.value.forEach((headline) => {
      map[headline.headlineId] = headline.title
    })
    return map
  })

  /**
   * 게시판 정보 설정
   * @param boardId
   * @param perPage 페이지 당 게시물 수
   */
  function setBoardInfo(boardId: string, perPage?: number) {
    boardInfo.value.boardId = boardId
    if (perPage) {
      boardInfo.value.perPage = perPage
    }
  }
  /**
   * 게시판 말머리 조회 및 할당
   */
  async function fetchHeadlineList() {
    try {
      if (!boardInfo.value.boardId) return
      const { isHeadline, boardHeadlines } = await $api.board.getBoardInfo(
        boardInfo.value.boardId
      )
      if (isHeadline && boardHeadlines.length) {
        searchCondition.value.headlineId = '0'
        headlineList.value = [{ headlineId: '0', title: '전체' }]
        headlineList.value.push(...boardHeadlines)
      } else {
        headlineList.value = []
        searchCondition.value.headlineId = undefined
      }
    } catch {
      //
    }
    return headlineList.value
  }
  /**
   * 게시글 목록 조회 및 할당
   * @param pageNo
   */
  async function fetchThreadInfo(pageNo = 1) {
    try {
      const {
        threads,
        totalElements,
        totalPages,
        numberOfElements,
        blockStartKey,
        blockStartNo
      } = await $api.board.getThreadList({
        boardId: boardInfo.value.boardId,
        pageNo,
        perPage: boardInfo.value.perPage,
        headlineId: searchCondition.value.headlineId,
        keywords: searchCondition.value.keywords,
        searchKeywordType: searchCondition.value.searchKeywordType,
        blockStartKey: threadInfo.value.blockStartKey,
        blockStartNo: threadInfo.value.blockStartNo
      })
      threads.forEach((thread) => {
        // 게시글 제목 내 escape 문자 일괄 치환
        thread.title = unescape(thread.title)
        if (headlineList.value.length && thread.headlineId) {
          thread['headlineName'] = headlineMap.value[thread.headlineId]
        }
      })

      threadInfo.value.threads = threads
      threadInfo.value.totalElements = totalElements
      threadInfo.value.totalPages = totalPages
      threadInfo.value.numberOfElements = numberOfElements
      threadInfo.value.blockStartKey = blockStartKey
      threadInfo.value.blockStartNo = blockStartNo
      curPage.value = pageNo
    } catch (e) {
      initBoardState()
    }
    await router.replace({
      query: {
        pageNo,
        headlineId: searchCondition.value.headlineId,
        keywords: searchCondition.value.keywords,
        searchKeywordType: searchCondition.value.searchKeywordType,
        blockStartKey: threadInfo.value.blockStartKey,
        blockStartNo: threadInfo.value.blockStartNo
      }
    })
  }

  /**
   * 게시판 저장소 초기화
   * - 주로 게시판 페이지를 떠날 때 사용
   */
  function $reset() {
    initBoardState()
  }

  /**
   * 검색조건 초기화 및 게시물 조회
   */
  async function refresh() {
    $reset()
    await fetchHeadlineList()
    await fetchThreadInfo()
  }
  return {
    boardInfo,
    headlineList,
    headlineMap,
    curPage,
    searchCondition,
    threadInfo,
    a2sObjPrefix,
    setA2sObjPrefix,
    setBoardInfo,
    fetchHeadlineList,
    fetchThreadInfo,
    $reset,
    refresh
  }
})
